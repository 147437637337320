import {useSvyStore} from "./Stores/svy";

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require("./bootstrap");

import { createApp } from "vue";
import { createPinia } from 'pinia'
import App  from "./App.vue"
import Contact  from "./components/Survey-Contact.vue"
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

var resize = require('vue-resize-directive')
var sprintf = require('sprintf-js').sprintf

var pinia = createPinia();

if( window.CREATE === "map" ){

    axios.get('/info/' + window.APP_GRID )
        .then( response => {

            console.log( "Creating Map" );

            createApp( App )
                .provide('SurveyInformation', response.data )
                .use( createPinia() )
                .mount("#app" );
        })
        .catch(error => {
            console.log(error)
        })
}

if( window.CREATE === "contact" ){

    createApp( Contact )
        .use(VueReCaptcha, {
            siteKey: '6LeSWhwhAAAAAKyujba1E3urP-IZBJMNafv_szDi' ,
                useRecaptchaNet: true,
                autoHideBadge: true
        }
        )
        .mount("#app" );
}





