
import {transform} from 'ol/proj';
import {GetSurvey, GetSurveyLL} from "./api";
import {calcScale} from "./map";
import {useSvyStore} from "../../Stores/svy";

export function RegisterMapEvents( mapVue , mapObj ){

    const svyStore = useSvyStore()

    mapObj.on("pointermove", function (event) {
        svyStore.setMousePosition( transform( event.coordinate, 'EPSG:3857', 'EPSG:4326') )
    });

    mapObj.on("click", function (event) {

        if( svyStore.mousePOS ){
            GetSurveyLL( this , svyStore.grid , svyStore.svy_lvl , svyStore.mousePOS[1] , svyStore.mousePOS[0] , function( svyOBJ ){
                svyStore.SetProcessing( false );
                svyStore.setSelected( true );
                svyStore.setHighlightedSVY( svyOBJ );
            })
        }
    });

    mapVue.mapObj.on('moveend', function(e) {
        svyStore.SetScale( calcScale( mapVue.mapObj ) );
    });

}