import { defineStore } from 'pinia'

export const useSvyStore = defineStore('svyStore', {

    state: () => ({
        _isProcessing: false,
        _isSearching: false,
        _isSelected: false,
        _highlightedSVY: null,
        _highlightedPNT: null,
        _mousePOS: null,
        _goZoom: false,
        _mouseTimeoutObject: null,
        _SurveyInfo: null,
        _grid: null,
        _svy_lvl: null,
        _searchError: null,
        _scale: 0.0
    }),
    getters: {
        Processing: (state) => state._isProcessing,
        Searching: (state) => state._isSearching,
        Selected: (state) => state._isSelected,
        highlightedSVY: (state) => ( state._highlightedSVY === null ) ? "" : state._highlightedSVY,
        highlightedPNT: (state) => ( state._highlightedPNT === null ) ? "" : state._highlightedPNT,
        mousePOS: (state) => state._mousePOS,
        isGoZoom: (state) => state._goZoom ,
        mouseTimeoutObj: (state) => state._mouseTimeoutObject,
        surveyInfo: (state) => state._SurveyInfo,
        grid: (state) => state._grid,
        svy_lvl: (state) => state._svy_lvl,
        scale: (state) => state._scale,
        searchError:(state) => state._searchError
    },
    actions:{
        SetProcessing( onoff ){
            this._isProcessing = onoff;
        },
        SetSearching( onoff ){
            this._isSearching = onoff;
        },
        setSelected(selected){
            this._isSelected = selected
        },
        setHighlightedSVY( SVY ){
            this.$patch({
                _highlightedSVY: SVY
            })
        },
        setHighlightedPNT( PNT ){
            this.$patch({
                _highlightedPNT: PNT
            })
        },
        setMousePosition( oPOS ){
            this.$patch({
                _mousePOS: oPOS
            })
        },
        goZoom( state ){
            this.$patch({
                _goZoom: state
            })
        },
        setSurveyInformation( svyInfo ){
            this.$patch({
                _SurveyInfo: svyInfo
            })
        },
        SetGrid( grid ){
            this.$patch({
                _grid: grid
            })
        },
        setSurveyLevel( svylvl ){
            this.$patch({
                _svy_lvl: svylvl
            })
        },
        setSearchResult( svyObj ){
            this.$patch({
                _highlightedSVY: svyObj,
                _isSelected: true
            });
        },
        SetScale( scale ){
            this.$patch({
                _scale: scale
            })
        },
        ClearHighlighted(){
            this.$patch({
                _isSelected:false,
                _highlightedSVY: null,
                _highlightedPNT: null,
            })
        },
        SetSearchError( err ){

//            console.log( "**************" + err );

            this.$patch({
                _searchError: err
            })
       },
    }

});

