
<script>
import {Map, View} from 'ol';

import TileLayer from 'ol/layer/Tile.js';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import OSM from 'ol/source/OSM.js';
import Point from 'ol/geom/Point';
import {Fill, RegularShape, Stroke, Style,Circle, Text } from 'ol/style';
import {defaults as defaultInteractions, DragRotateAndZoom, DragPan , DragRotate , DragZoom , KeyboardZoom , KeyboardPan , MouseWheelZoom } from 'ol/interaction.js';
import {defaults as defaultControls, Control, ScaleLine, OverviewMap, Zoom, ZoomSlider} from 'ol/control.js';
import Extent from 'ol/extent';
import {createStringXY, toStringHDMS, toStringXY} from 'ol/coordinate';
import {METERS_PER_UNIT} from 'ol/proj/Units.js';
import MousePosition from 'ol/control/MousePosition';
import TileWMSSource from 'ol/source/TileWMS.js';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import {fromLonLat} from 'ol/proj';
import {transform} from 'ol/proj';
import WKT from 'ol/format/WKT.js';
import MVT from 'ol/format/MVT';
import {getCenter} from 'ol/extent.js';

import {CreateMapControls,createStringDMS,calcScale} from './Utils/map';
import {highlightStyleFunc} from "./Utils/sytles";
import {RegisterMapEvents} from "./Utils/events";
import {GetSurveyInformation, GetSurveyLL} from "./Utils/api";
import {useSvyStore} from '../Stores/svy'

export default {
  name: "survey-map" ,
  props: ['highlightColor','pointColor'],
  setup() {

    const svyStore = useSvyStore()

    return { svyStore }
  },
  data() {
    return {
      mapOBj : null,
      zoomLevel : -1
    }
  },
  created: function() {

    window.addEventListener('resize', function(){
      if( this.mapObj != null ){
        this.mapObj.updateSize();
      }
    });

  },
  mounted() {

    const svyStore = useSvyStore()

    var lat = svyStore.surveyInfo.start_lat ;
    var lon = svyStore.surveyInfo.start_lon ;
    var lvl = svyStore.surveyInfo.start_lvl ;

    this.mapObj = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
          name: "default"
        })
      ],
      target: "map",
      loadTilesWhileAnimating: true,
      view: new View({
        center: fromLonLat([lon, lat]),
        zoom: lvl
      })
    });

    CreateMapControls( this.mapObj );

    var layer = new TileLayer({
      name: name ,
      source: new TileWMSSource( ({
        url: "https://wms.surveygrids.com/geoserver/grids/wms" ,
        params: {'LAYERS': svyStore.surveyInfo.map.layers } ,
        serverType: 'geoserver'
      }))
    });

    this.mapObj.addLayer( layer );

    var highlightSource = new VectorSource({
      name: "highlight" ,
      projection: "EPSG:4326",
      format: new MVT(),
    });

    var highlightLayer = new VectorLayer({
      name: "highlight" ,
      source: highlightSource,
      style: this.getHighlightStyle( false ),
      projection: "EPSG:3857",
    });

    highlightLayer.setVisible( true );

    this.mapObj.addLayer( highlightLayer );

    var pointSource = new VectorSource({
      name: "point" ,
      projection: "EPSG:4326",
      format: new MVT(),
    });

    var pointlightLayer = new VectorLayer({
      name: "point" ,
      source: pointSource,
      style: this.getPointStyle( false ),
      projection: "EPSG:3857",
    });

    pointlightLayer.setVisible( true );

    this.mapObj.addLayer( pointlightLayer );

    var mousePOSSource = new VectorSource({
      name: "mousePOSSource" ,
      projection: "EPSG:4326",
      format: new MVT(),
    });

    var mousePOSLayer = new VectorLayer({
      name: "mousePOSLayer" ,
      source: mousePOSSource,
      style: this.getPointStyle( false ),
      projection: "EPSG:3857",
    });

    mousePOSLayer.setVisible( true );

    this.mapObj.addLayer( mousePOSLayer );

    RegisterMapEvents( this , this.mapObj )

    svyStore.$onAction(( args , var2 ) => {

//      console.log( "onAction " + args.name + " "  );

      switch( args.name ){
        case "setSurveyLevel":
          svyStore.ClearHighlighted();
          break;
        case "setSearchResult" :
          this.MoveMap( svyStore );
          break;
        case "SetProcessing" :
          break;
        case "setHighlightedSVY" :
          break;
        case "setHighlightedPNT" :
          if( svyStore.Selected === true ){
            this.highlightPoint( svyStore )
          }
          break;
        case "setSelected" :
          this.clearhighlightPointLayer()
          this.highlightObject( svyStore )
          break;
        case "destroyTimeout" ://
          this.changeMousePosition( svyStore )
          break;
        case "goZoom" :
          this.ZoomMap(svyStore)
          break;
        case "setSurveyLevel" :
          this.changeMousePosition( svyStore );
          break;
        case "SetSearching" :
          if( svyStore.Selected === true ){
            console.log( "Moving Map");
            this.MoveMap( svyStore );
          }
          break;
      }

      this.zoomLevel = parseFloat( this.mapObj.getView().getZoom() ).toFixed(0) ;

    })

    svyStore.$subscribe((mutation, state) => {

      if( svyStore.Selected === true ){
        this.clearhighlightPointLayer()
        this.highlightPoint( svyStore )
      }

      if(svyStore.Processing === false && svyStore.mouseTimeoutObj == null ) {
        // console.log( "$subscribe setHighlightedSVY" )
        this.highlightObject( svyStore )
      }

      if(svyStore.isgoZoom === true && svyStore.highlightedSVY != null ){

        if (svyStore.Selected === false && svyStore.Processing === false ) {
        }
      }

      this.ResizeMap();
    });

  },
  watch: {
  },
  methods: {

    changeMousePosition: function( svyStore ){

    },
    highlightObject: function ( state , panMap ) {

      var lyr = null;

      if(( lyr = this.getLayer( "highlight" ) ) != null  ){

        var svyOBJ = null ;

        lyr.getSource().clear();

        if((svyOBJ = state.highlightedSVY) != null && svyOBJ.wkt != null ){

          var format = new WKT();

          var feature = format.readFeature(svyOBJ.wkt);

          feature.setId( svyOBJ.svy_desc );

          feature.setStyle( this.getHighlightStyle() );

          feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

          lyr.getSource().addFeature( feature );
        }
      }

    },
    clearhighlightLayer: function ( svyOBJ , panMap ) {

      this.getLayer( "highlight" , function( lyr ){
        lyr.getSource().clear();
      })

    },
    highlightPoint: function ( state , panMap ) {

    var lyr = null;

    if(( lyr = this.getLayer( "point" ) ) != null ){

      lyr.getSource().clear();

      var svyPNT = null ;

      if((svyPNT = state._highlightedPNT) != null ){

        const wkt = sprintf("POINT(%f %f)" , svyPNT[0] , svyPNT[1] )

        var format = new WKT();

        var feature = format.readFeature(wkt);

//      feature.setId( svyOBJ.svy_desc );

        feature.setStyle( this.getPointStyle() );

        feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

        lyr.getSource().addFeature( feature );
      }
    }
  },
    clearhighlightPointLayer: function ( svyOBJ , panMap ) {

    this.getLayer( "point" , function( lyr ){
      lyr.getSource().clear();
    })

  },
    highlightmousePOS: function ( state ) {

      var lyr = null;

      if(( lyr = this.getLayer( "mousePOSLayer" ) ) != null ){

        lyr.getSource().clear();

        var svyPNT = null ;

        if((svyPNT = state._mousePOS) != null ){

          const wkt = sprintf("POINT(%f %f)" , svyPNT[0] , svyPNT[1] )

          var format = new WKT();

          var feature = format.readFeature(wkt);

          feature.setStyle( this.getPointStyle() );

          feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

          lyr.getSource().addFeature( feature );
        }
      }
    },
    clearhighlightmousePOS: function ( svyOBJ , panMap ) {

      this.getLayer( "point" , function( lyr ){
        lyr.getSource().clear();
      })

    },
    getLayer: function ( layerName ) {

      var retLayer = null

      this.mapObj.getLayers().forEach( function (lyr) {
        if( lyr.get('name') === layerName ){
          retLayer = lyr ;
        }
      });

      return retLayer;
    },
    getHighlightStyle : function ( ){

      var sStroke = new Stroke({
        color: this.highlightColor,
        width: ( useSvyStore().Selected === false ) ? 1 : 3
      });

      var sStyle = new Style({
        stroke: sStroke,
        zIndex: 999
      });

      return sStyle ;
    },
    getPointStyle : function ( ){

      var sStroke = new Stroke({
        color: this.pointColor,
        width: ( useSvyStore().Selected === false ) ? 1 : 3
      });

      const fill = new Fill({color: this.highlightColor });

      var sStyle = new Style({
        image: new RegularShape({
          fill: fill,
          stroke: sStroke,
          points: 4,
          radius: 10,
          radius2: 0,
          angle: Math.PI / 4,
        })});

      return sStyle ;
    },
    ZoomMap( store )
    {
      var svyOBJ = null ;

      if((svyOBJ = store.highlightedSVY) != null && svyOBJ.wkt != null ){

        var format = new WKT();

        var feature = format.readFeature( svyOBJ.wkt );

        var ext = feature.getGeometry().getExtent() ;
        var cnt = getCenter( ext );

        var projCtr = transform( cnt, 'EPSG:4326', 'EPSG:3857' )

        var zoomLevel = store.surveyInfo.zoom_lvl[ store.svy_lvl ] ;

        console.log( zoomLevel );

        this.mapObj.getView().animate({
          center: projCtr,
          zoom: zoomLevel,
          duration: 1000
        });

      }
    },
    MoveMap( store )
    {
      var svyOBJ = null ;

      if(( svyOBJ = store.highlightedSVY ) != null && svyOBJ.wkt != null ){

        var format = new WKT();

        var feature = format.readFeature( svyOBJ.wkt );

        var ext = feature.getGeometry().getExtent() ;
        var cnt = getCenter( ext );

        var projCtr = transform( cnt, 'EPSG:4326', 'EPSG:3857' )

        this.mapObj.getView().animate({
          center: projCtr,
          duration: 1000
        });

      }
    },
    ResizeMap(){

    }
  }
}

</script>

<style>

#map{

}
.ol-control,.ol-scale-line
{
  padding:2px;
  position:absolute;
}

.ol-box
{
  border:2px solid #00f;
  border-radius:2px;
  box-sizing:border-box;
}

.ol-mouse-position
{
  position:absolute;
  right:8px;
  top:8px;
}

.ol-scale-line
{
  background:rgba(25, 70, 27, .3);
  border-radius:4px;
  bottom:8px;
  left:8px;
}

.ol-scale-line-inner
{
  border:1px solid #eee;
  border-top:none;
  color:#eee;
  font-size:10px;
  margin:1px;
  text-align:center;
  will-change:contents,width;
}

.ol-overlay-container
{
  will-change:left,right,top,bottom;
}

.ol-unsupported
{
  display:none;
}

.ol-viewport .ol-unselectable
{
  -moz-user-select:none;
  -ms-user-select:none;
  -webkit-tap-highlight-color:transparent;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  user-select:none;
}

.ol-control
{
  background-color:rgba(25, 70, 27, .4);
  border-radius:4px;
}

.ol-control:hover
{
  background-color:rgba(25, 70, 27, .6);
}

.ol-zoom
{
  left:.5em;
  top:.5em;
}

.ol-rotate
{
  right:.5em;
  top:.5em;
  transition:opacity .25s linear,visibility 0 linear;
}

.ol-rotate.ol-hidden
{
  opacity:0;
  transition:opacity .25s linear,visibility 0 linear .25s;
  visibility:hidden;
}

.ol-zoom-extent
{
  left:.5em;
  top:4.643em;
}

.ol-full-screen
{
  right:.5em;
  top:.5em;
}

.ol-control button
{
  background-color:rgba(25, 70, 27, .5);
  border:none;
  border-radius:2px;
  color:#fff;
  display:block;
  font-size:1.14em;
  font-weight:700;
  height:1.375em;
  line-height:.4em;
  margin:1px;
  padding:0;
  text-align:center;
  text-decoration:none;
  width:1.375em;
}

.ol-control button::-moz-focus-inner
{
  border:none;
  padding:0;
}

.ol-zoom-extent button
{
  line-height:1.4em;
}

.ol-compass
{
  display:block;
  font-size:1.2em;
  font-weight:400;
  will-change:transform;
}

.ol-touch .ol-control button
{
  font-size:1.5em;
}

.ol-touch .ol-zoom-extent
{
  top:5.5em;
}

.ol-control button:focus,.ol-control button:hover
{
  background-color:rgba(25, 70, 27, .6);
  text-decoration:none;
}

.ol-zoom .ol-zoom-in
{
  border-radius:2px 2px 0 0;
}

.ol-zoom .ol-zoom-out
{
  border-radius:0 0 2px 2px;
}

.ol-attribution
{
  bottom:.5em;
  max-width:calc(100%-1.3em);
  right:.5em;
  text-align:right;
}

.ol-attribution ul
{
  color:#000;
  font-size:.7rem;
  line-height:1.375em;
  margin:0;
  padding:0 .5em;
  text-shadow:0 0 2px #fff;
}

.ol-attribution li
{
  display:inline;
  line-height:inherit;
  list-style:none;
}

.ol-attribution li:not(:last-child):after
{
  content:" ";
}

.ol-attribution img
{
  max-height:2em;
  max-width:inherit;
  vertical-align:middle;
}

.ol-attribution button,.ol-attribution ul
{
  display:inline-block;
}

.ol-attribution.ol-collapsed ul
{
  display:none;
}

.ol-attribution.ol-logo-only ul
{
  display:block;
}

.ol-attribution:not(.ol-collapsed)
{
  background:rgba(255,255,255,.8);
}

.ol-attribution.ol-uncollapsible
{
  border-radius:4px 0 0;
  bottom:0;
  height:1.1em;
  line-height:1em;
  right:0;
}

.ol-attribution.ol-logo-only
{
  background:0 0;
  bottom:.4em;
  height:1.1em;
  line-height:1em;
}

.ol-attribution.ol-uncollapsible img
{
  margin-top:-.2em;
  max-height:1.6em;
}

.ol-attribution.ol-logo-only button,.ol-attribution.ol-uncollapsible button
{
  display:none;
}

.ol-zoomslider
{
  height:200px;
  left:.5em;
  top:4.5em;
}

.ol-zoomslider button
{
  height:10px;
  position:relative;
}

.ol-touch .ol-zoomslider
{
  top:5.5em;
}

.ol-overviewmap
{
  bottom:.5em;
  left:.5em;
}

.ol-overviewmap.ol-uncollapsible
{
  border-radius:0 4px 0 0;
  bottom:0;
  left:0;
}

.ol-overviewmap .ol-overviewmap-map,.ol-overviewmap button
{
  display:inline-block;
}

.ol-overviewmap .ol-overviewmap-map
{
  border:1px solid #7b98bc;
  height:150px;
  margin:2px;
  width:150px;
}

.ol-overviewmap:not(.ol-collapsed) button
{
  bottom:1px;
  left:2px;
  position:absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,.ol-overviewmap.ol-uncollapsible button
{
  display:none;
}

.ol-control:hover
{
  background-color:rgba(25, 70, 27, .2);
}

.ol-control button {
  background-color: $color10;
}

.ol-zoomslider-thumb {
  background-color: rgba(25, 70, 27, .3);
}

.ol-mouse-position
{
  position:absolute;
  right:8px;
  top:8px;
  padding: 5px;
  background-color: rgba(25, 70, 27, .3);
  color:white;
  font-size: 12px;
  border: 1px solid;
  border-radius: 5px;
}

.rotate-north {
  top: 180px;
  left: .5em;
}

.ol-DMS-mouse-position {
  position: absolute;
  right: 8px;
  top: 25px;
  padding: 5px;
  background-color: #19461b;
  color: white;
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
}

.ol-DEC-mouse-position {
  position: absolute;
  right: 8px;
  top: 55px;
  padding: 5px;
  background-color: #19461b;
  color: white;
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
}

.ol-10TM-mouse-position {
  position: absolute;
  right: 8px;
  top: 85px;
  padding: 5px;
  background-color: #19461b;
  color: white;
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
}
</style>

<template>
  <div id="map" style="height: calc(100vh - 231px);" >  </div>
</template>