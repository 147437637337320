
<script>

import {useSvyStore} from './Stores/svy'
import SurveyMap from './components/Survey-Map.vue'
import SurveyDetail from './components/Survey-Detail.vue'
import SurveySearch from './components/Survey-Search.vue'
import SurveyContact from './components/Survey-Contact.vue'

import { inject } from 'vue'

export default {
  name: "survey-app",
  inject: ['SurveyInformation'],
  setup() {
    const svyStore = useSvyStore()

    svyStore.setSurveyInformation( inject('SurveyInformation') );

    return { svyStore }
  },
  components: {
    SurveyMap,
    SurveyDetail,
    SurveySearch
  },
  data() {
    return {
      grid:window.APP_GRID,
      svy_lvl:window.APP_SVY_LEVEL,
      svy_sel: null
    }
  },
  created: function() {

    const svyStore = useSvyStore()

    svyStore.SetGrid( this.grid );
    svyStore.setSurveyLevel( this.svy_lvl );

  },
  computed: {

  },
  mounted() {

    const svyStore = useSvyStore();

    svyStore.$subscribe((mutation, state) => {

      if( svyStore.surveyInfo != null ){

        this.svy_sel = {}

        for( var idx = 0; idx < svyStore.surveyInfo.svy_sel.length ; idx++ ){

          var sel = svyStore.surveyInfo.svy_sel[ idx ] ;

          this.svy_sel[ sel[0] ] = sel[1] ;
        }
      }

    });
  },
  watch: {

  },
  methods: {
    mouseMove: function (event ) {
    },
    IsSurveyLevelSelected(svy_lvl){
      return useSvyStore().svy_lvl;
    },
    setSurveyLevel(svy_lvl){
      return useSvyStore().setSurveyLevel( svy_lvl );
    },
    getSurveyName(){
      if( useSvyStore().surveyInfo != null ){
        return useSvyStore().surveyInfo.name;
      }
    },
    getSurveyABBR(){
      if( useSvyStore().surveyInfo != null ){
        return useSvyStore().surveyInfo.abbr;
      }
    },
    getScale() {
      return useSvyStore().scale;
    },
    onResize(e) {
    },
    searchError() {
      return useSvyStore().searchError ;
    },
    hasSearchError() {

      const svyStore = useSvyStore();

//      console.log( svyStore.searchError ) ;

      if( svyStore.searchError != null && svyStore.searchError.length > 0 ){
        return true;
      }else
        return false ;
    },
    clearSearchError() {
      useSvyStore().SetSearchError("") ;
    },
  }
}
</script>

<template>

  <h1 class="p-2 ">{{getSurveyName()}} ({{getSurveyABBR()}})</h1>

  <div class="flex flex-row p-1" >
    <survey-search ></survey-search>
    <div v-if="hasSearchError()">
      <button type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-rose-500 hover:text-rose-900 hover:bg-rose-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500" @click="clearSearchError">
        <span class="sr-only">Close menu</span>
        <!-- Heroicon name: outline/x -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <p class="text-rose-700 pl-2">{{searchError()}}</p>
      </button>
    </div>
  </div>

  <div class="flex flex-row p-1">
    <div class="p-2 pt-2" v-for="( svy_name,svy_lvl) in svy_sel" :ID="svy_lvl+svy_name" >
      <input type="radio" @click="setSurveyLevel(svy_lvl)" :checked="svy_lvl == IsSurveyLevelSelected(svy_lvl)" name="sel_svy" >
      <label class="px-1" :for="svy_lvl+svy_name">{{svy_name}}</label>
    </div>
  </div>

  <div class ="flex flex-row p-2">
    <survey-map id="map" class="flex flex-grow gap-1 h-screen" highlightColor="#FF0000" pointColor="#006400"></survey-map>
    <survey-detail class="flex w-survey-detail" ></survey-detail>
  </div>

  <div id="footer" class="bg-white fixed inset-x-0 bottom-0 p-2">
    <p>Scale M 1:{{getScale()}}</p>
  </div>

</template>