import {useSvyStore} from "../../Stores/svy";

export function GetSurveyLL( mapVue , grid, svy_lvl , lat , lon, func )
{
    var svyOBJ = null ;
    const svyStore = useSvyStore();

    const params = {
        api_key: "public",
        lat: lat,
        lon: lon,
        grid: grid,
        svy_lvl: svy_lvl
    };

    if( svyStore.Processing === false ){
        svyStore.SetProcessing( true );
    axios
        .get('/ll2svy' , { params } )
        .then( response => {
            func( response.data )
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => {

        })
    }
}

export function GetSurveyPoints( svyDesc )
{
    var svyOBJ = null ;
    const svyStore = useSvyStore();

    const params = {
        api_key: "public",
        svyDesc: svyDesc
    };

    axios
        .get('/svypoints' , { params } )
        .then( response => {

            func( response.data )

            svyStore.SetProcessing( false )

        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => svyStore.SetProcessing( false ) )

}
export function GetSurveyInformation( gridName , func )
{
    /*
    var svyOBJ = null ;

    axios
        .get('/info/' + gridName )
        .then( response => {
            func( response.data )
        })
        .catch(error => {
            console.log(error)
        })
*/
}