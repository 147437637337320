


<script>

import {useSvyStore} from "../Stores/svy";

export default   {
  name: "survey-search",
  setup() {
    const svyStore = useSvyStore()

    return { svyStore }
  },
  components: {

  },
  data() {
    return {
      surveyDescription : "" ,
    }

  },
  created: function() {

  },
  computed: {
    isSearching() {
      return useSvyStore().Searching ;
    }
  },
  mounted() {
    const svyStore = useSvyStore()
    svyStore.$subscribe((mutation, state) => {
      if( useSvyStore().highlightedSVY != null && !useSvyStore().Searching ){
        this.surveyDescription = useSvyStore().highlightedSVY.svy_desc ;
      }
    });
  },
  watch: {

  },
  methods: {
    searchSurvey( event ) {

      console.clear();

      console.log( "Search " + this.surveyDescription );

      const svyStore = useSvyStore();

      const params = {
        api_key: "public",
        grid: useSvyStore().surveyInfo.abbr,
        svy_lvl: useSvyStore().svy_lvl,
        search: this.surveyDescription
      };

      svyStore.setSelected( false ) ;
      svyStore.setHighlightedSVY( null ) ;
      svyStore.SetSearching( true ) ;
      svyStore.SetSearchError( "" ) ;

      axios
          .get('/search' , { params } )
          .then( response => {

            if( response.data.has_error === 1 ){

              console.log( "XXXXXXXXXXXXXXXXXXXXXXXXXx" ) ;

              console.log( response.data );

         //     var err = Object.values( response.data.errors).join(", ") + " " + Object.values( response.data.verify).join(", ");
         //     var err =   response.data.errors.join(", ") + " " + response.data.verify.join(", ");

//              console.log( err ) ;

              console.log( response.data.errors ) ;

              console.log( "-----------------------------" ) ;

              svyStore.SetSearchError( Object.values( response.data.errors).join(", ") + " " + Object.values( response.data.verify).join(", ") ) ;
            }else{
              svyStore.setHighlightedSVY( response.data ) ;
              svyStore.setSelected( true ) ;
            }

          })
          .catch(error => {
          })
          .finally(() => {
            svyStore.SetSearching( false ) ;
          })

      event.preventDefault();
    }
  }
}
</script>

<template>
  <form >
    <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search Survey</label>
    <div class="relative">
      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
        <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
      </div>
      <input style="min-width: 400px;" type="search" id="default-search" class="block p-4 pl-10 w-70 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500" placeholder="" v-model="surveyDescription">
      <button v-if="!isSearching" type="submit" class="text-white
                                    absolute
                                    right-2.5
                                    bottom-2.5
                                    bg-green-800
                                    hover:bg-green-700
                                    focus:ring-4
                                    focus:outline-none
                                    focus:ring-green-300
                                    font-medium
                                    rounded-lg
                                    text-sm
                                    px-4
                                    py-2
                                    dark:bg-green-600
                                    dark:hover:bg-green-700
                                    dark:focus:ring-green-800"
                                    @click="searchSurvey"
                                    >Search</button>

      <button v-else-if="isSearching" type="submit" class="text-white
                                    absolute
                                    right-2.5
                                    bottom-2.5
                                    bg-gray-300
                                    hover:bg-green-800
                                    focus:ring-4
                                    focus:outline-none
                                    focus:ring-green-300
                                    font-medium
                                    rounded-lg
                                    text-sm
                                    px-4
                                    py-2
                                    dark:bg-green-600
                                    dark:hover:bg-green-700
                                    dark:focus:ring-green-800
                                    disabled"
              @click="searchSurvey"
      >Search</button>
    </div>

  </form>

</template>