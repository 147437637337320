<script>
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

export default {
  name: "survey-contact" ,
  setup() {
    console.log( "setup survey-contact" );

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    const recaptcha = async () => {

      console.log( " Inside recaptcha" );

      await recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')

      // Do stuff with the received token.

    }
    return {
      recaptcha
    }

  },
  data() {
    return {
      isSuccess: false,
      isComplete: false,
      isProcessing: false,
      frmFullName: "" ,
      frmEmail: "" ,
      frmMessage: "",
      frmResponse: "",
    }
  },
  computed: {
  },
  mounted() {
    console.log( "mounted survey-contact" );
  },
  watch: {
  },
  methods: {
    onsubmit( event ){

      this.frmResponse = "" ;

      const formData = new FormData();

      this.isProcessing = true ;

      formData.append("frmFullName", this.frmFullName);
      formData.append("frmEmail", this.frmEmail);
      formData.append("frmMessage", this.frmMessage);

      this.$recaptcha("login").then( ( token ) => {

        formData.append("g-recaptcha-response", token );

        axios
            .post(
                "message",
                formData,
                {
                  headers: {
                    Accept: "application/json",
                  },
                }
            )
            .then(
                (response) => {
                  this.isSuccess = response.data.success === 1 ;
                  this.frmResponse = response.data.message ;
                },
                (response) => {

                }
            ).finally(() => {
              this.isProcessing = false ;
              this.isComplete = true ;
            });
      });

      console.log("onsubmit");
      event.preventDefault()
    },
  }
}

</script>

<template>
  <div class="max-w-screen-xl mt-24 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg">
    <div class="flex flex-col justify-between">
      <div>
        <h2 class="text-4xl lg:text-5xl font-bold leading-tight">Contact</h2>
        <div class="text-gray-700 mt-8"> Please send us any questions, comments or feedback. </div>

      </div>
    </div>
    <div class="">
      <div>
        <span class="uppercase text-sm text-gray-600 font-bold">Full Name</span>
        <input v-model="frmFullName" class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" type="text" placeholder="">
      </div>
      <div class="mt-8">
        <span class="uppercase text-sm text-gray-600 font-bold">Email</span>
        <input v-model="frmEmail" class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" type="text">
      </div>
      <div class="mt-8">
        <span class="uppercase text-sm text-gray-600 font-bold">Message</span>
        <textarea  v-model="frmMessage"
            class="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
      </div>
      <div class="mt-8" v-if="isComplete === false" >
        <button v-if="isProcessing === false" @click="onsubmit" class="uppercase text-sm font-bold tracking-wide bg-green-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline " >
          Send Message
        </button>
        <button v-if="isProcessing === true" @click="onsubmit" disabled class="cursor-wait uppercase text-sm font-bold tracking-wide bg-gray-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline " >
          Sending ...
        </button>
      </div>
      <div>
        <p>{{frmResponse}}</p>
      </div>
    </div>
  </div>
</template>