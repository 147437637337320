<script>

import {useSvyStore} from "../Stores/svy";
var wkt = require('terraformer-wkt-parser');

export default {
  name: "survey-detail" ,
  setup() {

  },
  data() {
    return {
      points : null,
      svy_lbl_val: null,
    }
  },
  computed: {
    isDisabled() {
      return (this.points ? false : true);
    },
    isSelected() {
      return useSvyStore().Selected;
    },
    GetSurveyInfomation(){
      return useSvyStore().surveyInfo
    },
    GetSelectedSurvey(){
      return useSvyStore().highlightedSVY
    }
  },
  mounted() {

    const svyStore = useSvyStore()

    svyStore.$subscribe((mutation, state) => {

      const svyStore = useSvyStore() ;

      if( svyStore.Selected && svyStore.highlightedSVY.wkt != null ) {

        var primitive = wkt.parse( svyStore.highlightedSVY.wkt );

        this.points = [] ;

        for( var idx = 0; idx < primitive.coordinates[0].length; idx++ ){

          var coord = primitive.coordinates[0][ idx ] ;

          var sLon = coord[0].toFixed(7).toString();
          var sLat = coord[1].toFixed(7).toString();

          sLon = sLon.replace( ".0000000" ,'' );
          sLat = sLat.replace( ".0000000" ,'' );

          this.points.push( [ parseFloat(sLon) , parseFloat(sLat) ] ) ;
        }

        // console.log( primitive );
        // this.points = primitive.coordinates[0] ;

      }else{
        this.points = null
      }

      this.svy_lbl_val = {};

      if( svyStore.surveyInfo != null && svyStore.highlightedSVY != null && svyStore.Selected === true ){

        if( svyStore.highlightedSVY.svy_val ){

          if( svyStore.highlightedSVY.svy_val.SV1.length > 0 ){
            this.svy_lbl_val[ svyStore.surveyInfo.svy_lbl.SV1 ] = svyStore.highlightedSVY.svy_val.SV1 ;
          }

          if( svyStore.highlightedSVY.svy_val.SV2.length > 0 ){
            this.svy_lbl_val[ svyStore.surveyInfo.svy_lbl.SV2 ] = svyStore.highlightedSVY.svy_val.SV2 ;
          }

          if( svyStore.highlightedSVY.svy_val.SV3.length > 0 ){
            this.svy_lbl_val[ svyStore.surveyInfo.svy_lbl.SV3 ] = svyStore.highlightedSVY.svy_val.SV3 ;
          }

          if( svyStore.highlightedSVY.svy_val.SV4.length > 0 ){
            this.svy_lbl_val[ svyStore.surveyInfo.svy_lbl.SV4 ] = svyStore.highlightedSVY.svy_val.SV4 ;
          }

          if( svyStore.highlightedSVY.svy_val.SV5.length > 0 ){
            this.svy_lbl_val[ svyStore.surveyInfo.svy_lbl.SV5 ] = svyStore.highlightedSVY.svy_val.SV5 ;
          }

          if( svyStore.highlightedSVY.svy_val.SV6.length > 0 ){
            this.svy_lbl_val[ svyStore.surveyInfo.svy_lbl.SV6 ] = svyStore.highlightedSVY.svy_val.SV6 ;
          }

        }
      }
    })

  },
  watch: {

  },
  methods: {
    mouseOver: function (point ) {
      useSvyStore().setHighlightedPNT( point )
    },
    mouseLeave: function (point ) {
      useSvyStore().setHighlightedPNT( null )
    },
    clickZoom: function () {
      useSvyStore().goZoom( true )
    },
    getSurveyDescription: function () {

      if( useSvyStore().highlightedSVY != null ){
        return useSvyStore().highlightedSVY.svy_desc
      }else{
        return ""
      }
    }
  }
}
</script>

<style>

#ZoomBtn
{
  background-color: rgba(25, 70, 27, 1);
  color:white;
}

#ZoomBtn:disabled {
  visibility:hidden;
}
</style>

<template>
<div id="survey-detail" class="container flex-col h-full p-2" >

  <h2 class="font-bold pb-1.5">{{getSurveyDescription()}}</h2>
  <div class="absolute right-2">
    <button ID="ZoomBtn" class="block h-6 w-11 text-sm text-white rounded-lg bg-green-800 hover:bg-green-400" @click="clickZoom" :disabled='isDisabled'>Zoom</button>
  </div>

  <div>
    <table>
      <tr v-for="(val, lbl) in svy_lbl_val" class="hover:bg-green-500" >
        <td class="font-bold pr-5" >{{lbl}}</td> <td>{{val}}</td>
      </tr>
    </table>
  </div>

  <div v-if="isSelected"  class=" text-sm h-96 max-h-full overflow-y-auto pt-2.5"  @mouseleave="mouseLeave" style="height: calc(100vh - 380px);">
    <table class='table-auto sticky' >
      <thead>
      <tr>
        <th class="text-left pr-1" >Point #</th>
        <th class="text-left pr-6" >Latitude </th>
        <th class="text-left" >Longitude</th>
      </tr>
      </thead>
      <tr v-if = "isSelected" class="hover:bg-green-500" @mouseover="mouseOver([GetSelectedSurvey.cc_lon , GetSelectedSurvey.cc_lat])" >
        <td >C</td> <td>{{GetSelectedSurvey.cc_lat}}</td> <td>{{GetSelectedSurvey.cc_lon}}</td>
      </tr>
      <tr v-for="(point, index) in points" class=" hover:bg-green-500" @mouseover="mouseOver(point)" >
        <td>{{index + 1 }}</td> <td>{{point[1]}}</td> <td>{{point[0]}}</td>
      </tr>
    </table>
  </div>

</div>

</template>