import MousePosition from 'ol/control/MousePosition';
import {createStringXY, toStringHDMS, toStringXY} from 'ol/coordinate';
import {defaults as defaultControls, Control, ScaleLine, OverviewMap, Zoom, ZoomSlider} from 'ol/control.js';
import {defaults as defaultInteractions, DragRotateAndZoom, DragPan , DragRotate , DragZoom , KeyboardZoom , KeyboardPan , MouseWheelZoom } from 'ol/interaction.js';
import Units, { METERS_PER_UNIT } from 'ol/proj/Units';

export function CreateMapControls( mapObj ){

    var mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(6),
        projection: 'EPSG:4326',
        map: mapObj,
        className: 'ol-DEC-mouse-position',
    });

    mapObj.addControl(mousePositionControl);

    var mousePositionControlDMS = new MousePosition({
        projection: 'EPSG:4326',
        coordinateFormat: createStringDMS(6),
        map: mapObj,
        className: 'ol-DMS-mouse-position',
    });

    mapObj.addControl(mousePositionControlDMS);

    /*
        var mousePositionControl10TM = new MousePosition({
            projection: 'EPSG:3401',
            coordinateFormat: createStringXY(6),
            map: mapObj,
            className: 'ol-10TM-mouse-position',
        });
        mapObj.addControl(mousePositionControl10TM);
    */

    var scaleLineControl = new ScaleLine();

    scaleLineControl.setUnits("metric");

    mapObj.addControl(scaleLineControl);

    mapObj.addControl(new ZoomSlider());

    mapObj.addInteraction(new DragRotate());

    mapObj.addInteraction(new KeyboardPan({
        pixelDelta: 256
    }));

    mapObj.addInteraction(new DragZoom());
}

export function createStringDMS(opt_fractionDigits) {
    return (
        /**
         * @param {Coordinate} coordinate Coordinate.
         * @return {string} String XY.
         */
        function(coordinate) {
            return toStringHDMS(coordinate, opt_fractionDigits);
        }
    );
}

export function calcScale( mapObj ) {

    var resolution = mapObj.getView().getResolution() ;
    var units = mapObj.getView().getProjection().getUnits();

    console.log( units );

    var dpi = 25.4 / 0.28;
    var mpu = METERS_PER_UNIT[units];
    var scale = resolution * mpu * 39.37 * dpi;

    /*  if (scale >= 9500 && scale <= 950000) {
        scale = Math.round(scale / 1000) + "K";
    } else if (scale >= 950000) {
        scale = Math.round(scale / 1000000) + "M";
    } else {
        scale = Math.round(scale);
    }  */

//    console.log( scale )

    return Math.round( scale ) ;
}

/*
const DOTS_PER_INCH = 72;
const INCHES_PER_METRE = 39.37;

function inchesPreUnit(unit: Units): number {
    return METERS_PER_UNIT[unit] * INCHES_PER_METRE;
}

function mapRatioScale({ map, toRound = true }): number {
    const resolution = map.getView().getResolution();
    const unit = map.getView().getProjection().getUnits();

    let scale = resolution * inchesPreUnit(unit) * DOTS_PER_INCH;
    return toRound ? Math.round(scale) : scale;
}
*/
